import React from "react"
import LayoutA5 from "../../components/layout-a5"
import enrollStyles from "./a5_enroll.module.css"
import { navigate } from "@reach/router"


export default  class A5Enroll extends React.Component {

  chooseEvent=()=>{
    navigate('/demos/themeA5/enrollStep')
  }

  render() {
    const color='#0000ff'
    const  raceDetail={
      raceName:'2019苏州簇格马拉松',
      poster:'../../images/a2/3.png',
      startTime:'2019-08-16 10:00:00',
      endTime:'2019-08-16 10:00:00',
      raceTime:'2019-08-16 10:00:00',
      location:'苏州市工业园区',
      events:[{name:"全程马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"},
        {name:"半程马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"},
        {name:"迷你马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"}]

    }
    return(
      <LayoutA5 >
        <div className={enrollStyles.enrollContainer}>
          <div className={enrollStyles.topPoster}>
          </div>
          {/*{this.state.step===0&&*/}
          <div>
            <div className={enrollStyles.raceInfoContainer}>
              <div className={enrollStyles.racePoster}>
                <img alt="hello" src={require('../../images/a2/3.png')}/>
              </div>
              <div className={enrollStyles.raceInfo}>
                <div className={enrollStyles.raceTitle}>{raceDetail.raceName}</div>
                <div className={enrollStyles.startTime}>报名开始：{raceDetail.startTime}</div>
                <div className={enrollStyles.endTime}>报名截止：{raceDetail.endTime}</div>
                <div className={enrollStyles.raceTime}>比赛开始：{raceDetail.raceTime}</div>
                <div className={enrollStyles.raceLocation}>赛事地点：{raceDetail.location}</div>
              </div>
            </div>


            <div className={enrollStyles.eventsContainer}>
              <div className={enrollStyles.eventsPrice}>
                {raceDetail.events.map((event,index)=><div className={enrollStyles.eventPrice} key={index}>
                  ￥{event.price}
                </div>)}
              </div>
              <div className={enrollStyles.eventsInfo}>
                {raceDetail.events.map((event,index)=><div className={enrollStyles.eventItem} key={index}>
                  <div style={{flex:'1'}}>
                    <div className={enrollStyles.eventName}>{event.name}</div>
                    <div className={enrollStyles.eventTip}>{event.tip}</div>
                  </div>
                  <div className={enrollStyles.eventEnrollBtn} style={{background:color}} onClick={this.chooseEvent}>立即报名</div>
                  <div className={enrollStyles.eventPriceBtn} style={{background:'#4040e5'}}>￥{event.price}</div>
                </div>)}
              </div>
            </div>
          </div>
          {/*}*/}
          {/*{*/}
          {/*  this.state.step>0&&<EnrollStep color={color} step={this.state.step} addStep={this.addStep} deleteStep={this.deleteStep}/>*/}
          {/*}*/}
        </div>
      </LayoutA5>
    )
  }
}